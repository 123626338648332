import { render, staticRenderFns } from "./IconWarningFilled.vue?vue&type=template&id=65af3942&"
import script from "./IconWarningFilled.vue?vue&type=script&lang=ts&"
export * from "./IconWarningFilled.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports