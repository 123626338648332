import { render, staticRenderFns } from "./IconSparkleFill.vue?vue&type=template&id=37af20e6&"
import script from "./IconSparkleFill.vue?vue&type=script&lang=ts&"
export * from "./IconSparkleFill.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports