
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { PixRenderExportType } from '@pixcap/ui-core/models/store/projectexports.interface';

	@Component({
		name: 'IconServerRender',
	})
	export default class IconServerRender extends Vue {
		@Prop() exportType: PixRenderExportType;
		PixRenderExportType = PixRenderExportType;
	}
